<template>
<div>
  <div>
    <i :class="trigger.icon"></i> {{ trigger.label }}
    <span v-if="fullDate" class="full-date">( {{ fullDate }} )</span>
  </div>

  <b-modal id="date-modal" v-model="isShowModal" title="Date" size="lg">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Keywords</label>
          <multiselect
            v-model="automation.keywords"
            :options="keywords"
            :multiple="true"
            track-by="id"
            placeholder="Select keywords"
            label="name"
          ></multiselect>
        </div>
      </div>
      <div class="col-3">
        <label>Month</label>
        <select v-model="automation.options.month" class="form-control">
          <option value="">Month</option>
          <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
      </div>
      <div class="col-3">
        <label>Day</label>
        <select v-model="automation.options.day" class="form-control">
          <option value="">Day</option>
          <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
      </div>
      <div class="col-6">
        <label>Time to Send</label>
        <TimePicker v-model="automation.options.time" />
      </div>
      <div class="col-md-12 mt-2">
        <strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Timezone: {{ settingTimezone }}</span></strong>
        <div>Current Time: {{ serverTime }}</div>
        <!-- <div class="mt-3">Local Timezone: {{ localTimeZone }}</div>
        <div>Local Time: {{ localTime }}</div> -->
      </div>
    </div>

    <div class="form-group mt-3 small-checkbox">
      <b-form-checkbox v-model="automation.options.stop_on_reply" name="stop_on_reply">
        Stop on contact reply 
        <span v-b-tooltip.hover class="cursor-pointer" title="When someone replies to a message in the automation the remainder of the automation will be cancelled" style="vertical-align: middle;">
          <i class="uil uil-question-circle"></i>
        </span>
      </b-form-checkbox>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import moment from 'moment-timezone'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    localTimeZone() {
      let timezone = moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },
    localTime() {
      return moment().format('MMM D, YYYY hh:mm A')
    },
    serverTime() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return moment()
        .tz(timezone)
        .format('MMM D, YYYY hh:mm A')
    },
    settingTimezone() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },
    fullDate() {
      return this.automation.options.month + '/' + this.automation.options.day + ' ' + moment(this.automation.options.time, 'HH:mm').format('hh:mm A')
    }
  },

  mounted() {
    const month = ('0' + moment().month()).slice(-2);
    const day = ('0' + moment().day()).slice(-2);
    this.automation.options = {
      ...{ month, day, campaigns: [], time: '09:00' },
      ...this.automation.options
    }

    this.$parent.$on('showModal', this.showModal)
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.getKeywords()
      this.isShowModal = true
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .full-date {
    display: block;
    font-size: 13px;
    font-weight: normal;
  }
</style>

<style lang="scss">
#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>